import React from "react";
import Header from "../components/Header";
import Un from "../img/UnitedNations.png";
import Members from "../components/Members";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import Aippm from "../img/Committees/AIPPM-01.jpg";
import CCC from "../img/Committees/CCC-01.jpg";
import Ipc from "../img/Committees/IPC-01.jpg";
import Unwomen from "../img/Committees/UN women-01.jpg";
import Unhrc from "../img/Committees/UNHRC-01.jpg";
import Unhsc from "../img/Committees/UNHSC-01.jpg";
import Unodc from "../img/Committees/UNODC-01.jpg";
import Shreya from "../img/shreya.JPG";
import Shivangi from "../img/shivangi.JPG";
import Shivang from "../img/shivang.JPG";
import Mahana from "../img/mahana.JPG";
import Arnav from "../img/arnav.JPG";
import Asstha from "../img/asstha.JPG";
import Saumya from "../img/saumya.JPG";
import Abhijatya from "../img/Abhijatya.jpg";
import Jatin from "../img/Jatin.jpg";

const Home = () => {
  const members = [
    {
      img: Arnav,
      data: "Arnav Singh",
      department: "Chief Executive for Logistics",
    },
    {
      img: Shivang,
      data: "Shivang singh",
      department: "Chief Executive for Finance",
    },
    {
      img: Abhijatya,
      data: "Abhijatya Chauhan",
      department: "Chief Executive for Media Affairs",
    },
    {
      img: Jatin,
      data: "Jatin Kaushal 祥子",
      department: "Cheif Executive for Technical Affairs",
    },
    {
      img: Mahana,
      data: "Samarth Mahana",
      department: "Chief Executive for Marketing",
    },
  ];
  const member1 = [
    {
      img: Shivangi,
      data: "Shivangi Mishra",
      department: "Chief Coordinator of Delegate Affairs",
    },
    {
      img: Shreya,
      data: "Shreya Upadhyay",
      department: "Chief Coordinator of Media & Conference Management",
    },
    {
      img: Saumya,
      data: "Saumya Sharma",
      department: "Chief Executive for Registrations",
    },
    {
      img: Asstha,
      data: "Aastha Tripathi",
      department: "Chief Coordinator of Administrative Affairs",
    },
  ];

  useGSAP(() => {
    gsap.to("#committees .committee", {
      transform: "rotate(calc(360deg/7 * var(--i)))",
      transformOrigin: "370px",
      opacity: 1,
      ease: "power3.out",
      scrollTrigger: {
        trigger: "#committees",
        // markers: true,
        scrub: 0.5,
        start: "30% 80%",
        end: "bottom 100%",
      },
    });
  });

  return (
    <>
      <Header />
      <main>
        <h1 className="heading-1">Our Team Members</h1>
        <Members cards={member1} />
        <Members cards={members} />
      </main>
      {window.innerWidth >= 600 ? (
        <section id="committees" className="">
          <img src={Un} alt="" className="bg" />
          <h1 className="heading-1">
            The <br />
            Committees
          </h1>
          <div className="container">
            <div className="icon">
              <div className="committee" style={{ "--i": 1 }}>
                <Link to="/committees">
                  <img src={Aippm} alt="" />
                </Link>
              </div>
              <div className="committee" style={{ "--i": 2 }}>
                <Link to="/committees">
                  <img src={CCC} alt="" />
                </Link>
              </div>
              <div className="committee" style={{ "--i": 3 }}>
                <Link to="/committees">
                  <img src={Ipc} alt="" />
                </Link>
              </div>
              <div className="committee" style={{ "--i": 4 }}>
                <Link to="/committees">
                  <img src={Unwomen} alt="" />
                </Link>
              </div>
              <div className="committee" style={{ "--i": 5 }}>
                <Link to="/committees">
                  <img src={Unhrc} alt="" />
                </Link>
              </div>
              <div className="committee" style={{ "--i": 6 }}>
                <Link to="/committees">
                  <img src={Unhsc} alt="" />
                </Link>
              </div>
              <div className="committee" style={{ "--i": 7 }}>
                <Link to="/committees">
                  <img src={Unodc} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div>
          <h1><Link to='/committees' style={{padding: '3rem', color: 'var(--color1)'}}>The Committees</Link></h1>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default Home;
