import React, { useEffect } from "react";
import Kanta from "../img/kanta.JPG";
import Sajid from "../img/sajidsir.jpg";
import Secretariats from "../img/secretariats.JPG";
import { useParams } from "react-router-dom";
import gsap from "gsap";

const Addressals = () => {

  const {id} = useParams();

  useEffect(()=>{
    id && gsap.to(window, {scrollTo: `#${id}`}) 
  }, [id])

  return (
    <main>
      <center>
      <img src={Kanta} alt="" className="testimonial-image mt-5" /></center>
      <div className="container flex responsive">

        <div id="principal">
          <p className="para-1">
            <h1 className="heading-1 left">Principal's message</h1>
            <div className="mt-5"></div>
            At Allenhouse Rooma, we believe that education extends far beyond
            textbooks. We foster a learning environment that encourages critical
            thinking, open dialogue, and a deep understanding of the world
            around us. Hence, we are thrilled to announce the inaugural edition
            of APSRMUN (Allenhouse Public School Rooma Model United Nations),
            with the theme: Dialogue, Resolution, and Action. <br /> <br />{" "}
            APSRMUN is not just a simulation; it's a transformative experience.
            Students will step into the shoes of world leaders, tackling
            real-world issues through research, debate, and collaboration. We've
            meticulously curated committees with agendas reflecting the most
            pressing challenges of our times. From unemployment and cyber
            security to social justice, these issues will demand innovative
            solutions and out-of-the-box thinking. <br /> <br /> But APSRMUN
            goes beyond mimicking diplomacy. We aim to equip students with the
            empathy and understanding necessary to truly embody the roles they
            play. Through in-depth research and character development, students
            will not just play as world leaders, they will feel and believe as
            them as well. This immersive experience fosters a sense of global
            responsibility, allowing students to develop the skills and
            confidence to become the changemakers of tomorrow. We invite schools
            and students to join us in this exciting venture. APSRMUN promises
            to be a journey of intellectual growth, personal discovery, and the
            first step towards shaping a brighter future. <br /> <br /> Warm
            Regards,
            <br /> Dr. Kirti Chaudhary <br /> Principal
          </p>
        </div>
        
      </div>
      <center>
      <img style={{minWidth: 450, height: 700}} src={Sajid} alt="" className="testimonial-image mt-5" />

      </center>
      <div className="container flex">
        <div id="faculty">
          <p className="para-1">
            <h1 className="heading-1 left">Faculty Advisor's message</h1>
            <div className="mt-5"></div>
            At Allenhouse Rooma, we believe that education extends far beyond
            textbooks. We foster a learning environment that encourages critical
            thinking, open dialogue, and a deep understanding of the world
            around us. Hence, we are thrilled to announce the inaugural edition
            of APSRMUN (Allenhouse Public School Rooma Model United Nations),
            with the theme: Dialogue, Resolution, and Action. <br /> <br />{" "}
            APSRMUN is not just a simulation; it's a transformative experience.
            Students will step into the shoes of world leaders, tackling
            real-world issues through research, debate, and collaboration. We've
            meticulously curated committees with agendas reflecting the most
            pressing challenges of our times. From unemployment and cyber
            security to social justice, these issues will demand innovative
            solutions and out-of-the-box thinking. <br /> <br /> But APSRMUN
            goes beyond mimicking diplomacy. We aim to equip students with the
            empathy and understanding necessary to truly embody the roles they
            play. Through in-depth research and character development, students
            will not just play as world leaders, they will feel and believe as
            them as well. This immersive experience fosters a sense of global
            responsibility, allowing students to develop the skills and
            confidence to become the changemakers of tomorrow. We invite schools
            and students to join us in this exciting venture. APSRMUN promises
            to be a journey of intellectual growth, personal discovery, and the
            first step towards shaping a brighter future. <br /> <br /> Warm
            Regards,
            <br /> Dr. Kirti Chaudhary <br /> Principal
          </p>
        </div>
      </div>
      <center>
        <img
          src={Secretariats}
          alt=""
          className="testimonial-image full mt-5"
        />
      </center>
      <div className="container flex">
        <div id="secretariat">
          <p className="para-1">
            <h1 className="heading-1 left">Secretariat's message</h1>
            <div className="mt-5"></div>
            As Allenhouse Public School Rooma’s inaugural Model United Nations
            conference begins; we, the student secretariat, believe that true
            growth comes from open dialogue, where young minds confront complex
            global issues and work towards resolutions. <br />
            <br />
            <span style={{ fontWeight: 500 }}>
              {" "}
              Dialogue, Resolution, and Action
            </span>{" "}
            - these are the cornerstones of APSRMUN. We envision a platform
            where students, regardless of background or experience, can develop
            the critical thinking and communication skills necessary to become
            future global leaders. Through committee participation, you will be
            challenged to understand and address pressing issues that affect
            different classes, sections, and levels of society. <br /> <br />
            Our meticulously curated committees will focus on the most relevant
            topics of our time, fostering a dynamic and intellectually
            stimulating environment. This is your chance to step into the shoes
            of diplomats, researchers, and activists, crafting resolutions that
            showcase your problem-solving prowess. APSRMUN isn't just a
            conference; it's a catalyst for change. <br /> <br /> We invite
            students from all schools to join this journey of learning,
            collaboration, and action. Be a part of APSRMUN and make your voice
            heard on the world stage!
            <br />
            <br />
            Warm Regards, <br />
            The Secretariat
          </p>
        </div>
      </div>
     
    </main>
  );
};

export default Addressals;
