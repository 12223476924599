import React from "react";

const Contact = () => {
  return (
    <main>
      <div className="para-1">
        <h1 className="heading-1 left">Contact us</h1>
        
        <h3 className="mt-2">Email: <a href="mailto:allenhouseroomamun@gmail.com">allenhouseroomamun@gmail.com</a></h3>
        <h3 className="mt-2">(1) Phone no.: <a href="tel:+91 9696173388">+91 9696173388</a></h3>
        <h3 className="mt-2">(2) Phone no.: <a href="tel:+91 8826637781">+91 8826637781</a></h3>
      </div>
    </main>
  );
};

export default Contact;
