import "./App.css";

import Navbar from "./components/Navbar";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { ReactLenis } from "lenis/react";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import Committees from "./pages/Committees";
import Addressals from "./pages/Addressals";
import Contact from "./pages/Contact";

function App() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  return (
    <ReactLenis options={{ orientation: "vertical", duration: 1.2 }} root>
      <>
        <Loader />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/committees/" element={<Committees />} />
          <Route path="/addressals/:id?" element={<Addressals />} />
        </Routes>
      </>
    </ReactLenis>
  );
}

export default App;
