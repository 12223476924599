import React, { useEffect } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Members = ({ cards = [{ img: "", data: "" }] }) => {
  const [targets, setTarget] = React.useState();
  const [data, setData] = React.useState("");
  const [department, setDepartment] = React.useState("");

  const { contextSafe } = useGSAP(() => {
    gsap.fromTo(
      ".card",
      { y: 50, opacity: 0 },
      {
        y: 0,
        stagger: 0.05,
        duration: 0.2,
        opacity: 1,
        ease: "back.out",
        scrollTrigger: {
          trigger: ".members",
        //   markers: true,
          start: "top 80%",
          end: "bottom 90%",
          scrub: 1,
          //   once: false,
        },
      }
    );

    gsap.set(".img-hover", { xPercent: -50, yPercent: -50 });
    let xTo = gsap.quickTo(".img-hover", "x", {
        duration: 0.3,
        ease: "power3.out",
      }),
      yTo = gsap.quickTo(".img-hover", "y", {
        duration: 0.2,
        ease: "power3.out",
      });
    const handleImgHover = contextSafe((e) => {
      xTo(e.clientX);
      yTo(e.clientY);
    });
    window.addEventListener("mousemove", handleImgHover);
    return () => window.removeEventListener("mousemove", handleImgHover);
  });

  useGSAP(() => {
    setTarget(gsap.utils.toArray(".card"));
  });

  useEffect(() => {
    targets?.forEach((element, index) => {
      gsap.set(element, {
        zIndex: index + 1,
        // rotate: gsap.utils.random(-5, 5),
        scale: 1,
      });
    });
  }, [targets]);

  const CardHover = contextSafe((event, data, department) => {
    gsap.to(event.target, {
      scale: 1.3,
      duration: 0.2,
      ease: "back.out",
      rotate: gsap.utils.random(-5, 5),
    });
    gsap.set(event.target, { zIndex: 100 });
    setData(data);
    setDepartment(department);
  });
  const CardHoverBack = contextSafe((event) => {
    gsap.to(event.target, {
      scale: 1,
      duration: 0.2,
      rotate: 0,
      ease: "back.out",
    });
    targets.forEach((element, index) => {
      element.style.zIndex = index + 1;
    });
    setData();
  });
  return (
    <div className="members">
      <div className={`img-hover ${data ? "active" : ""}`}>
        <p>{data}</p>
        <p className="department">{department}</p>
      </div>
      {cards.map((val, key) => {
        return (
          <div
            className="card"
            onMouseOver={(e) => CardHover(e, val.data, val.department)}
            onMouseLeave={CardHoverBack}
            key={key}
          >
            <img src={val.img} alt="" />
          </div>
        );
      })}
    </div>
  );
};

export default Members;
