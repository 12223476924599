import React from "react";

const Committees = () => {
  return (
    <section id="committees-page">
      <div className="para-1">
        <h1 className="heading-1 left">United Nations Women (UNW)</h1>
        <div className="committees-block">
          <p>
            Agenda: Accelerating the achievement of gender equality and the
            empowerment of all women and girls by addressing poverty and
            strengthening institutions and financing with a gender perspective.​
          </p>
        </div>
      </div>
      <div className="para-1">
        <h1 className="heading-1 left">
          All India Political Parties Meet (AIPPM)
        </h1>
        <div className="committees-block">
          <p>
            Agenda: Deliberating on the record high unemployment rate in India
            and the necessary solutions to mitigate it.​
          </p>
        </div>
      </div>
      <div className="para-1">
        <h1 className="heading-1 left">International Press Corps (IPC)</h1>
        <div className="committees-block">
          <p>Agenda: Journalism and Photography ​</p>
        </div>
      </div>
      <div className="para-1">
        <h1 className="heading-1 left">
          United Nations Office on Drugs and Crime (UNODC)
        </h1>
        <div className="committees-block">
          <p>
            Agenda: Assessing the drug trade through black markets and dark web
            with special emphasis on cybersecurity measures to be taken to
            control dark web drug trade.​
          </p>
        </div>
      </div>
      <div className="para-1">
        <h1 className="heading-1 left">
          United Nations Historic Security Council (UNHSC)
        </h1>
        <div className="committees-block">
          <p>Agenda: Cuban Missile Crisis​</p>
        </div>
      </div>
      <div className="para-1">
        <h1 className="heading-1 left">
          United Nations Human Rights Council (UNHRC)
        </h1>
        <div className="committees-block">
          <p>
            Agenda: Deliberation upon civilian casualties in warzone with
            special emphasis on the civilian casual in Gaza and the Rafah
            Massacre.​
          </p>
        </div>
      </div>
      <div className="para-1">
        <h1 className="heading-1 left">Continuous Crisis Committee (CCC)</h1>
        <div className="committees-block">
          <p>Agenda: Date of the Conference (WW3)​</p>
        </div>
      </div>
    </section>
  );
};

export default Committees;
