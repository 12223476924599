import React from "react";
import "./css/Loader.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Loader = () => {
  useGSAP(() => {
    gsap
      .timeline()
      // .fromTo(
      //   ".stripes",
      //   { y: "-100%" },
      //   { y: 0, stagger: 0.1, ease: "circ.inOut" }
      // )
      // .fromTo(
      //   ".wrapper > h1",
      //   { opacity: 0, scale: 0.8 },
      //   { opacity: 1, scale: 1, ease: "expo.out" },
      //   "-=0.5"
      // )
      .to(
        ".wrapper > h1",
        { opacity: 0, scale: 0.7, ease: "expo.out" },
        "=+0.5"
      )
      .set(
        "#loader",
        { background: "transparent", pointerEvents: "none" },
        "-=0.5"
      )
      .to(".stripes", { y: "-100%", stagger: 0.1 }, "-=0.5")
      .fromTo(
        "header h1",
        {
          y: 50,
        },
        { y: 0, ease: "expo.out" },
        '-=0.8'
      )
      .fromTo(
        ".slogan",
        {
          y: 50,
        },
        {
          y: 0,
          stagger: 0.1,
          ease: "expo.out"
        }, '-=0.8'
      ).fromTo(
        ".upper p",
        {
          y: 50,
        },
        {
          y: 0,
          stagger: 0.1,
          ease: "expo.out"
        }, '-=0.8'
      );
  });

  return (
    <div id="loader">
      <div className="wrapper">
        <div className="stripes"></div>
        <div className="stripes"></div>
        <div className="stripes"></div>
        <div className="stripes"></div>
        <div className="stripes"></div>
        <div className="stripes"></div>
        <div className="stripes"></div>
        <h1>APSRMUN</h1>
      </div>
    </div>
  );
};

export default Loader;
