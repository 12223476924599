import React, { useEffect, useState } from "react";
import "./css/Navbar.css";
import Logo from "../img/munLogo.png";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  useGSAP(() => {
    gsap.to(".logo", {
      scrollTrigger: {
        trigger: "header",
        start: "100px top",
        end: "bottom 50%",
        scrub: 0.5,
      },
      scale: 0.6,
      ease: "expo",
    });
  });

  const location = useLocation()

  useEffect(()=>{
    setNavbar(false)
  }, [location.pathname])

  const navigate = useNavigate();

  return (
    <nav>
      <div className="logo" onClick={() => navigate("/")}>
        <img src={Logo} alt="" />
      </div>
      {window.innerWidth >= 930 ? (
        <div className="links">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="Dropdown">
              <Link to="/addressals">Addressals</Link>
              <div className="dropdown-content">
                <Link to="/addressals/principal">Principal</Link>
                <Link to="/addressals/faculty">Faculty advisor</Link>
                <Link to="/addressals/secretariat">Secretariat</Link>
              </div>
            </li>
            <li className="Dropdown">
              <Link href="/">Registration</Link>
              <div className="dropdown-content">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScEuCB28J9BsNSmJGFfoqLED6Ww_-vrSzY954CH7vJRozMJSA/viewform?pli=1">
                  School delegation
                </a>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdsrNzk5nDGO-xSwaKiCwtg6Tl3fFFmshWUcKN58b1If7Tneg/viewform?pli=1">
                  Individual registration
                </a>
              </div>
            </li>
            <li>
              <Link to="/committees">Committees</Link>
            </li>
            <li>
              <Link to="/contact">Contact us</Link>
            </li>
          </ul>
        </div>
      ) : (
        <div className="links-mobile">
          <div className={`icon ${navbar?"active":""}`} onClick={() => setNavbar((prev) => !prev)}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className={`slider ${navbar ? "active" : ""}`}>
            <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="under">
              <Link to="/addressals">Addressals</Link>
              <div className="under-links">
                <Link to="/addressals/principal">Principal</Link>
                <Link to="/addressals/faculty">Faculty advisor</Link>
                <Link to="/addressals/secretariat">Secretariat</Link>
              </div>
            </li>
            <li className="under">
              <Link href="/">Registration</Link>
              <div className="under-links">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScEuCB28J9BsNSmJGFfoqLED6Ww_-vrSzY954CH7vJRozMJSA/viewform?pli=1">
                  School delegation
                </a>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdsrNzk5nDGO-xSwaKiCwtg6Tl3fFFmshWUcKN58b1If7Tneg/viewform?pli=1">
                  Individual registration
                </a>
              </div>
            </li>
            <li>
              <Link to="/committees">Committees</Link>
            </li>
            <li>
              <Link to="/contact">Contact us</Link>
            </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
